import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files
import translationEN from "./locales/en.json";
// import translationNL from "./locales/nl.json";

const loadTranslations = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_STRAPI_API_HOST}/api/site-text`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
      },
    });
    const data = await response.json();
    return data.data.attributes.siteText;
  } catch (error) {
    console.error("Failed to load translations:", error);
    return {};
  }
};

const resources = {
  en: {
    translation: translationEN,
  },
  // nl: {
  //   translation: translationNL,
  // },
};

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  resources,
  lng: "en", // default language

  keySeparator: false,

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

loadTranslations().then((translations) => {
  i18n.addResourceBundle("en", "translation", translations, true, true);
});

export default i18n;
