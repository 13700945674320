import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import Hamburger from "hamburger-react";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/logos/logo_white_hor.svg";

const Header = () => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [activeMenus, setActiveMenus] = useState({});
  const [openMenus, setOpenMenus] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [hideHome, setHideHome] = useState(window.innerWidth < 1200);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
      setHideHome(window.innerWidth < 1140);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const menus = useMemo(
    () => [
      ...(!hideHome ? [{ key: "home", path: "/", title: t("home_title"), isNavLink: true }] : []),
      {
        key: "about",
        path: "/about",
        title: t("about_title"),
        subItems: [
          { path: "/about#sustainability", title: t("about_sustainability_title") },
          { path: "/about#certifications", title: t("about_certifications_header_title") },
          { path: "/about#partofmetisgroup", title: t("about_metis_header_title") },
        ],
      },
      {
        key: "technologies",
        path: "/technologies",
        title: t("technologies_title"),
        subItems: [
          { path: "/technologies?technology=iot", title: t("technologies_iot_title") },
          { path: "/technologies?technology=sensing", title: t("technologies_sensing_title") },
          { path: "/technologies?technology=evchargers", title: t("technologies_evchargers_title") },
          { path: "/technologies?technology=hmi", title: t("technologies_hmi_title") },
          { path: "/technologies?technology=powerelectronics", title: t("technologies_powerelectronics_title") },
        ],
      },
      {
        key: "markets",
        path: "/markets",
        title: t("markets_title"),
        subItems: [
          { path: "/markets#energy", title: t("markets_energy_title") },
          { path: "/markets#climate", title: t("markets_climate_title") },
          { path: "/markets#industry", title: t("markets_industry_title") },
        ],
      },
      {
        key: "services",
        path: "/services",
        title: t("services_title"),
        subItems: [
          { path: "/services?service=development#services-menu", title: t("services_development_title") },
          { path: "/services?service=manufacturing#services-menu", title: t("services_manufacturing_title") },
          { path: "/services?service=plcm#services-menu", title: t("services_plcm_title") },
        ],
      },
      { key: "news", path: "/news", title: t("news_title"), isNavLink: true },
      { key: "careers", path: "/careers", title: t("careers_title"), isNavLink: true },
      { key: "contact", path: "/contact", title: t("contact_title"), isNavLink: true },
    ],
    [t, hideHome]
  );

  useEffect(() => {
    const updatedActiveMenus = {};
    menus.forEach((menu) => {
      updatedActiveMenus[menu.key] = location.pathname.includes(menu.path);
    });
    setActiveMenus(updatedActiveMenus);
  }, [location, menus]);

  const handleNavigate = (path) => {
    navigate(path);
    setOpen(false);
    setOpenMenus({});
  };

  const toggleDropdown = (menuKey, forceClose = false) => {
    setOpenMenus((prev) => ({ ...prev, [menuKey]: forceClose ? false : !prev[menuKey] }));
  };

  const renderMenuItems = (menu) => {
    if (menu.isNavLink || isMobile) {
      return (
        <NavLink key={menu.key} to={menu.path} className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} onClick={() => setOpen(false)}>
          {menu.title}
        </NavLink>
      );
    } else {
      return (
        <NavDropdown
          key={menu.key}
          title={<div onClick={() => handleNavigate(menu.path)}>{menu.title}</div>}
          className={activeMenus[menu.key] ? "active" : ""}
          show={openMenus[menu.key]}
          onToggle={() => toggleDropdown(menu.key)}
          onMouseEnter={() => setOpenMenus((prev) => ({ ...prev, [menu.key]: true }))}
          onMouseLeave={() => setOpenMenus((prev) => ({ ...prev, [menu.key]: false }))}
        >
          {menu.subItems.map((item) => (
            <NavDropdown.Item
              key={item.path}
              onClick={(e) => {
                e.preventDefault();
                handleNavigate(item.path);
              }}
            >
              {item.title}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      );
    }
  };

  return (
    <Navbar id="navbar-menu" expanded={isOpen} fixed="top" bg="#007fb5" expand="lg">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <img src={Logo} alt="Company Logo" className="ame-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav">
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav">
          <Nav className="mx-auto text-center">{menus.map(renderMenuItems)}</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
